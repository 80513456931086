import { permanentRedirect } from 'next/navigation'
import { removeImageTypes } from 'utils/src/media'
import { SearchType } from '../(site)/search/types'
import { safeDecodeURIComponent } from './url'

/*
 * When searching on the site in the search bar we convert spaces to -,
 * but if somehow `/search/happy birthday` is entered in the address bar
 * we will redirect to `/search/happy-birthday
 */
export const whiteSpaceRedirect = (term: string) => {
    const decodedTerm = safeDecodeURIComponent(term)
    if (/.{0,}\s+.?/.test(decodedTerm)) {
        permanentRedirect(encodeURIComponent(decodedTerm.trim().replace(/ /g, '-')))
    }
}

/**
 * Decode URI-encoded string
 * Swap `-` for ` `
 * Remove image types (e.g. -gifs, -stickers, -clips)
 * @param searchTerm
 * @returns space separated string without type and search type
 */
export const processSearchTerm = (searchTerm: string): [string, SearchType] => {
    let term = safeDecodeURIComponent(searchTerm)
    const type: SearchType = getSearchType(term)
    term = removeImageTypes(term).replace(/-/g, ' ').trim()
    return [term, type]
}

export function getSearchType(decodedTerm: string): SearchType {
    return /(^|-)(transparents?|stickers?)(-|$)/.test(decodedTerm)
        ? 'stickers'
        : decodedTerm.endsWith('-clips') || decodedTerm.endsWith('-with-sound')
        ? 'clips'
        : 'gifs'
}
