'use client'

import { Banner } from '@/app/util/banner'
import { KevelAdType } from '@/app/util/kevel'
import type { KevelResponse } from '@/app/util/kevel-types'
import { createContext } from 'react'

export type AdsContextProps = {
    htlAds: string[]
    isHTLAdEnabled: (unitName: string) => boolean
    isKevelAdEnabled: (type: KevelAdType | 'any') => boolean
    pageName?: string
    kevelClientData?: KevelResponse
    banner?: Banner
    hasTopLeaderboardAd?: boolean
}

const AdsContext = createContext({
    htlAds: [],
    banner: undefined,
    isKevelAdEnabled: () => false,
    isHTLAdEnabled: () => false,
    pageName: undefined,
    kevelClientData: undefined,
} as AdsContextProps)

export default AdsContext
