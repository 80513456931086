'use client'
import { DeviceType } from '@/app/util/env'
import { ReactNode, createContext, useContext } from 'react'

type Type = DeviceType | undefined

export type UAParserProps = {
    deviceType: Type,
    isBot: boolean,
}

const UAParserContext = createContext({
    deviceType: undefined,
    isBot: false,
} as UAParserProps)

export const RenderProps = ({ children }: { children: (deviceType: Type, isBot: boolean) => ReactNode }) => {
    const { deviceType, isBot } = useContext(UAParserContext)
    return children(deviceType, isBot)
}

export default UAParserContext
