import { KevelRequestOptions, KevelResponse } from './kevel-types'

const siteId = 1300946
const networkId = 11727
const url = `https://e-${networkId}.adzerk.net/api/v2`
export type KevelAdType = 'banner' | 'grid'
export const getKevelContentByType = (response: KevelResponse | undefined, id: KevelAdType, type: string = 'raw') =>
    response?.decisions?.[id]?.contents?.find((c) => {
        return c.type === type
    })

export const getKevelGifIdByType = (response: KevelResponse | undefined, id: KevelAdType, type: string = 'raw') =>
    getKevelContentByType(response, id, type)?.data?.ctgif_id

export const bannerAd = { divName: 'banner', adType: 5 }
export const gridAd = { divName: 'grid', adType: 23 }

// banner 9ZBl22etqIDJ80joKj  grid WE4ml0Bcz7nnHt82tf
export function getKevelTest(banner = '', grid = '') {
    if (grid || banner) {
        return {
            decisions: {
                banner: {
                    contents: [{ type: 'raw', data: { ctgif_id: banner } }],
                },
                grid: {
                    contents: [{ type: 'raw', data: { ctgif_id: grid } }],
                },
            },
        } as any
    }
}

const fetchAd = async ({ placements, rating = 'unrated', keywords, region }: KevelRequestOptions) => {
    const body = {
        consent: { gdpr: true },
        placements: placements.map(({ divName, adType }) => ({
            divName,
            networkId,
            siteId,
            adTypes: [adType],
            eventIds: [],
            properties: {
                rating,
                region,
            },
            keywords,
        })),
    }
    // console.log(`fetch kevel ad`, body)
    const response = await fetch(url, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(body),
    })
    const result = await response.json()
    return result as KevelResponse
}

export default fetchAd
