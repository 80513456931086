export type SearchType = 'stickers' | 'gifs' | 'clips'
export const searchTypes: SearchType[] = ['gifs', 'stickers', 'clips'] as const

export const searchTypeToTitleType = (type: SearchType): string => {
    switch (type) {
        case 'stickers':
            return 'Stickers'
        case 'gifs':
            return 'GIFs'
        case 'clips':
            return 'Clips'
        default:
            return ''
    }
}

export const searchTypeToDescriptionType = (type: SearchType): string => {
    switch (type) {
        case 'stickers':
            return 'Stickers and Transparent GIFs'
        case 'clips':
            return 'Clips and GIFs with Sound'
        default:
            return 'GIFs'
    }
}
