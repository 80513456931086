import { IGif } from '@giphy/js-types'
import { uniqueId } from 'lodash'

// can't modify this, its used for when images is null
const images = (width: number = 1, height: number = 1, src: string = '') =>
    [
        'fixed_width',
        'downsized',
        'fixed_height_small_still',
        'fixed_width_downsampled',
        'downsized_large',
        'original',
        'original_still',
        'fixed_height_still',
        'fixed_width_small',
        'fixed_height_downsampled',
        'looping',
        'downsized_small',
        'fixed_width_small_still',
        'downsized_medium',
        'preview',
        'fixed_height',
        'fixed_width_still',
        'fixed_height_small',
        'downsized_still',
    ].reduce((acc: { [key: string]: { url: string; width: number; height: number } }, key) => {
        acc[key] = {
            url: src || 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=', // 1px black square
            width,
            height,
        }
        return acc
    }, {})

// check gif[isPlaceholder] to see if it's a placeholder
export const isPlaceholder = Symbol('isPlaceholder')
// Add other props to gif skeleton?
const createPlaceholderGif = (otherProps = {}, imageProps?: { width?: number; height?: number; src?: string }) => {
    return {
        [isPlaceholder]: true,
        bitly_url: '',
        create_datetime: '',
        embed_url: '',
        id: uniqueId('placeholder'),
        images: { ...images(imageProps?.width, imageProps?.height, imageProps?.src) },
        import_datetime: '',
        is_anonymous: false,
        is_community: false,
        is_featured: false,
        is_hidden: false,
        is_indexable: false,
        is_realtime: false,
        is_removed: false,
        is_sticker: false,
        rating: 'g',
        slug: '',
        tags: [],
        trending_datetime: '',
        update_datetime: '',
        username: '',
        url: '',
        ...otherProps,
    } as unknown as IGif
}

export default createPlaceholderGif
